import React, { useState, useEffect } from 'react';
import Login from './Login';
import Register from './Register';
import Game from './Game';
import Deposit from './Deposit';
import Withdrawal from './Withdrawal';
import io from 'socket.io-client';
import Pusher from 'pusher-js';


const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  encrypted: true,
});


// const BASE_API_URL = 'https://testboxgame.vercel.app';
//http://localhost:8080
// 
// const socket = io(BASE_API_URL);


function App() {
  const storedToken = localStorage.getItem('authToken') || '';
  const storedUserBalance = localStorage.getItem('userBalance') || 0;

  const [token, setToken] = useState(storedToken);
  const [userBalance, setUserBalance] = useState(parseInt(storedUserBalance));
  const [isSignedIn, setIsSignedIn] = useState(!!storedToken);
  const [selectedOption, setSelectedOption] = useState('play');
  const [showInstructions, setShowInstructions] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [authOption, setAuthOption] = useState('login');
  const [publicChannelName] = useState('public-channel');
  const [message, setMessage] = useState('');


  

// using socket 

  // useEffect(() => {
  //   // Connect to the server's Socket.IO instance
  //   const socket = io('https://testboxgame.vercel.app'); // Replace with your server URL

  //   // Listen for connect and disconnect events
  //   socket.on('connect', () => {
  //     console.log('WebSocket connected');
  //   });

  //   socket.on('disconnect', (reason) => {
  //     console.log('WebSocket disconnected:', reason);
  //   });

  //   // Listen for balance update events
  //   socket.on('balanceUpdate', ({ newBalance }) => {
  //     // Update the user balance in the state
  //     const updatedBalance = parseInt(newBalance, 10);
  //     setUserBalance(updatedBalance);

  //     // Update local storage
  //     localStorage.setItem('userBalance', updatedBalance.toString());

  //     console.log('Balance updated:', newBalance);
  //   });

  //   // Listen for the test event
  //   socket.on('testEvent', (testData) => {
  //     console.log('Received test event from the server:', testData);
  //   });

  //   // Log errors
  //   socket.on('error', (error) => {
  //     console.error('WebSocket error:', error);
  //   });

  //   // Log any other events for debugging
  //   socket.onAny((event, ...args) => {
  //     console.log('Received event:', event, args);
  //   });

  //   // Clean up the socket connection and event listeners when the component unmounts
  //   return () => {
  //     socket.disconnect();
  //     console.log('WebSocket disconnected');
  //   };
  // }, []); // No dependencies, runs once when the component mounts

  // useEffect(() => {
  //   console.log('Component rendered');

  //   const channel = pusher.subscribe('newBalance');

  //   // Log the current state of the Pusher connection
  //  console.log('Pusher connection state:', pusher.connection.state);

  //   channel.bind('balanceUpdate', function (data) {
  //     console.log('Received balance update:', data);
  //     alert(" Your Balance Updated ",JSON.stringify(data));

  //     setUserBalance(data.updatedBalance);
  //   });

  //   return () => {
  //     console.log('Unsubscribing from newBalance channel');
  //     pusher.unsubscribe('newBalance');
  //   };
  // }, []); 

  // useEffect(() => {
  //   console.log('Component rendered');

  //   const channel = pusher.subscribe('newBalance');

  //   console.log('Pusher connection state:', pusher.connection.state);

  //   const handleBalanceUpdate = (data) => {
  //     console.log('Received balance update:', data);

  //     // Check if the user balance has changed before updating local storage
  //     if (data.updatedBalance !== userBalance) {
  //       console.log('User balance has changed. Updating local storage and state.');
  //       localStorage.setItem('userBalance', data.updatedBalance);
  //       setUserBalance(data.updatedBalance);
  //       alert('Your Balance Updated Your new balance is: ' + JSON.stringify(data.updatedBalance));
  //     } else {
  //       console.log('User balance has not changed. Skipping local storage update.');
  //     }
  //   };

  //   channel.bind('balanceUpdate', handleBalanceUpdate);

  //   return () => {
  //     console.log('Unsubscribing from newBalance channel');
  //     pusher.unsubscribe('newBalance');
  //   };
  // }, [userBalance]); // Add userBalance to the dependency array


  useEffect(() => {
    // Fetch user data from your API when the component mounts
    const fetchUserData = async () => {
      try {
        const authToken = localStorage.getItem('authToken'); // Get authToken from local storage
  
        const response = await fetch('https://testboxgame.vercel.app/user-balance', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': authToken, // Include the authToken in the headers
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setUserBalance(data.balance); // Set userBalance with the retrieved balance
          setIsSignedIn(true);
  
          // Update local storage with the new userBalance
          localStorage.setItem('userBalance', data.balance);
        } else {
          // Handle error when fetching user data
          console.error('Failed to fetch user data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchUserData();
  }, []); // Run once when the component mounts
  
  



  





  useEffect(() => {
    const handleLogout = () => {
      localStorage.removeItem('authToken');
      localStorage.removeItem('userBalance');
      setToken('');
      setUserBalance(0);
      setIsSignedIn(false);
      setSelectedOption(null);
    };

    // Handle logout when the token is removed or expired
    if (!localStorage.getItem('authToken')) {
      handleLogout();
    }
  }, []);

  const handleLogin = async (userToken, balance) => {
    localStorage.setItem('authToken', userToken);
    localStorage.setItem('userEmail', userEmail);
    localStorage.setItem('userBalance', balance.toString());
    setUserEmail(userEmail);
    setToken(userToken);
    setUserBalance(balance);
    setIsSignedIn(true);
    setSelectedOption('play');
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userBalance');
    setToken('');
    setUserBalance(0);
    setIsSignedIn(false);
    setSelectedOption(null);
  };


  const handleRegister = async (userToken, balance) => {
    try {
      if (userToken && balance !== undefined) {
        localStorage.setItem('authToken', userToken);
        localStorage.setItem('userBalance', balance.toString());
        setToken(userToken);
        setUserBalance(balance);
        setIsSignedIn(true);
        setSelectedOption('play');
      } else {
        console.error('Invalid parameters received in handleRegister');
      }
    } catch (error) {
      console.error('Error in handleRegister:', error);
    }
  };
  
  // const storedToken = localStorage.getItem('authToken') || '';
  
  // const storedUserBalance = localStorage.getItem('userBalance') || 0;

  // const [token, setToken] = useState(storedToken);
  // const [userBalance, setUserBalance] = useState(parseInt(storedUserBalance));
  // const [isSignedIn, setIsSignedIn] = useState(!!storedToken);
  // const [selectedOption, setSelectedOption] = useState('play');
  // const [showInstructions, setShowInstructions] = useState(false);
  // const [authOption, setAuthOption] = useState('login'); // New state for authentication option

  // useEffect(() => {
  //   const handleLogout = () => {
  //     localStorage.removeItem('authToken');
  //     localStorage.removeItem('userBalance');
  //     setToken('');
  //     setUserBalance(0);
  //     setIsSignedIn(false);
  //     setSelectedOption(null);
  //   };
  //   // Handle logout when the token is removed or expired
  //   if (!localStorage.getItem('authToken')) {
  //     handleLogout();
  //   }
  // }, []);



  // const handleLogin = (userToken, balance) => {

  //   localStorage.setItem('authToken', userToken); // Persist token in localStorage
  //   localStorage.setItem('userBalance', balance); // Persist userBalance in localStorage
  //   setToken(userToken);
  //   setUserBalance(balance);
  //   setIsSignedIn(true);
  //   setSelectedOption('play'); // Display "Play" button after login
  // };

  // const handleLogout = () => {
  //   localStorage.removeItem('authToken'); // Remove token from localStorage
  //   setToken(null);
  //   setUserBalance(balance);
  //   setIsSignedIn(false);
  //   setSelectedOption(null);
  // };

  // const handleRegister = (userToken) => {
  //   localStorage.setItem('authToken', userToken); // Persist token in localStorage
  //   localStorage.setItem('userBalance', balance); // Persist userBalance in localStorage
  //   setToken(userToken);
  //   setUserBalance(balance);
  //   setIsSignedIn(true);
  //   setSelectedOption('play'); // Display "Play" button after registration
  // };

  const handleOption = (option) => {
    if (!isSignedIn) {
      console.log('Please sign in first');
      return;
    }

    setSelectedOption(option);
  };

  const toggleInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  const handleLoginSelect = () => {
    setAuthOption('login');
  };

  const handleRegisterSelect = () => {
    setAuthOption('register');
  };

  const styles = {
    container: {
      margin: '20px',
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      maxWidth: '400px',
      marginLeft: 'auto',
      marginRight: 'auto',
      background: '#fff',
      textAlign: 'center',
    },
    authContainer: {
      marginBottom: '20px',
    },
    userContainer: {
      marginBottom: '20px',
    },
    button: {
      display: 'inline-block',
      padding: '10px 20px',
      fontSize: '16px',
      fontWeight: 'bold',
      borderRadius: '5px',
      margin: '5px',
      cursor: 'pointer',
      border: 'none',
      color: '#fff',
      transition: 'background 0.3s ease',
    },
    playButton: {
      background: '#4CAF50',
    },
    depositButton: {
      background: '#2196F3',
    },
    withdrawButton: {
      background: '#f44336',
    },
    logoutButton: {
      background: '#333',
    },
    instructions: {
      marginTop: '20px',
    },
    loggedInMessage: {
      marginTop: '10px',
      fontSize: '18px',
      color: '#333',
    },
  };

  return (
    <div style={styles.container}>




    <h1>{isSignedIn ? 'Lucky Box Game' : 'Lucky Box Game'}</h1>

    <h2>{isSignedIn ? 'Win up  to 5,000 Ksh' : 'Win up  to 5,000 Ksh'}</h2>

      {!isSignedIn ? (
        <div style={styles.authContainer}>
          <button
            style={{
              ...styles.button,
              ...styles.playButton,
              ...(authOption === 'login' ? { background: '#ccc' } : null),
            }}
            onClick={handleLoginSelect}
          >
            Login
          </button>
          <button
            style={{
              ...styles.button,
              ...styles.depositButton,
              ...(authOption === 'register' ? { background: '#ccc' } : null),
            }}
            onClick={handleRegisterSelect}
          >
            Register
          </button>
          {authOption === 'login' && <Login onLogin={handleLogin} />}
          {authOption === 'register' && <Register onRegister={handleRegister} />}
        </div>
      ) : (
        <div style={styles.userContainer}>
          <p>User Balance: {userBalance} KSh</p>
          <button style={{ ...styles.button, ...styles.playButton }} onClick={() => handleOption('play')}>
            Play
          </button>
          <button style={{ ...styles.button, ...styles.depositButton }} onClick={() => handleOption('deposit')}>
            Deposit
          </button>
          <button style={{ ...styles.button, ...styles.withdrawButton }} onClick={() => handleOption('withdraw')}>
            Withdraw
          </button>
          <button style={{ ...styles.button, ...styles.logoutButton }} onClick={handleLogout}>
            Logout
          </button>
          <button style={{ ...styles.button, background: '#FFD700' }} onClick={toggleInstructions}>
            {showInstructions ? 'Hide Instructions' : 'Show Instructions'}
          </button>
          {selectedOption === 'play' && (
            <div>
              {showInstructions && (
                <div style={styles.instructions}>
                  <h2>How to Play:</h2>
                  <p>1. Follow the rules...</p>
                  <p>2. Play responsibly...</p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {selectedOption === 'play' && isSignedIn && (
        <Game token={token} userBalance={userBalance} setUserBalance={setUserBalance} />
      )}
      {selectedOption === 'deposit' && <Deposit userToken={token} />}
      {selectedOption === 'withdraw' && <Withdrawal userToken={token} userBalance={userBalance} setUserBalance={setUserBalance}/>}
      {isSignedIn && (
        <div style={styles.loggedInMessage}>
          <p>You are currently logged in!</p>
        </div>
      )}
    </div>
  );
}

export default App;





