import React, { useState } from 'react';

// Add this function outside your component
const renderErrorMessage = (message) => <p style={{ color: 'red' }}>{message}</p>;

const Game = ({ token, userBalance, setUserBalance }) => {
  const [betAmount, setBetAmount] = useState(20);
  const [userBox, setUserBox] = useState(1);
  const [result, setResult] = useState(null);

  const handlePlay = async () => {
    try {
      // Check if the bet amount is below 20
      if (betAmount < 20) {
        setResult({
          status: 'error',
          message: 'Bet amount should be 20 or more. Please enter a valid bet amount.',
        });
        return;
      }

      // Check if user has sufficient balance for the bet
      if (userBalance < betAmount) {
        // Inform the user about insufficient balance
        setResult({
          status: 'error',
          message: 'Low balance. Deposit funds or refresh for an updated balance after depositing',
        });
        return;
      }

      const response = await fetch('https://testboxgame.vercel.app/play-lucky-box', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token, // Include the token in the request header
        },
        body: JSON.stringify({ betAmount, userBox }),
      });

      const data = await response.json();

      // Update user balance after winning
      setUserBalance(data.balanceAfterBet);

      // Update local storage for user balance
      localStorage.setItem('userBalance', data.balanceAfterBet);

      setResult(data.result);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Lucky Box Game</h2>
      <div style={styles.inputContainer}>
        <label style={styles.label}>
          Bet Amount:
          <input type="number" value={betAmount} onChange={(e) => setBetAmount(e.target.value)} style={styles.input} />
        </label>
      </div>
      <div style={styles.inputContainer}>
        <label style={styles.label}>
          Choose your lucky box:
          <select value={userBox} onChange={(e) => setUserBox(e.target.value)} style={styles.input}>
            {[1, 2, 3, 4, 5, 6, 7].map((box) => (
              <option key={box} value={box}>
                Box {box}
              </option>
            ))}
          </select>
        </label>
      </div>
      <button style={styles.button} onClick={handlePlay}>
        Play
      </button>

      {/* Result display */}
      {result && (
        <div style={styles.resultContainer}>
          {result.status === 'error' ? (
            renderErrorMessage(result.message)
          ) : (
            <>
              <h3 style={styles.resultHeading}>Result: {result.phoneNumber}</h3>
              <p>Status: {result.result}</p>
              {result.prize && <p>Prize: {result.prize}</p>}
              {result.lostAmount && <p>Lost Amount: {result.lostAmount} KSh</p>}

              {/* Details display */}
              {result.details && (
                <div style={styles.detailsContainer}>
                  <h4 style={styles.detailsHeading}>Details:</h4>
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {Object.entries(result.details).map(([key, value]) => (
                      <li key={key} style={styles.detailItem}>
                        <span style={styles.detailKey}>{key}:</span>
                        {key === 'Lost Rewards' && Array.isArray(value) ? (
                          <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {value.map((reward, index) => (
                              <li key={index}>{reward}</li>
                            ))}
                          </ul>
                        ) : (
                          <span style={styles.detailValue}>
                            {Array.isArray(value) ? value.join(', ') : value}
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    margin: '20px',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    maxWidth: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  heading: {
    marginBottom: '20px',
    textAlign: 'center',
    color: '#333',
  },
  inputContainer: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#333',
  },
  input: {
    width: '100%',
    padding: '8px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  button: {
    width: '100%',
    padding: '10px 0',
    fontSize: '16px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  resultContainer: {
    marginTop: '20px',
  },
  resultHeading: {
    fontSize: '18px',
    color: '#333',
  },
  detailsContainer: {
    marginTop: '20px',
    borderTop: '1px solid #ddd',
    paddingTop: '10px',
  },
  detailsHeading: {
    fontSize: '18px',
    color: '#333',
    marginBottom: '10px',
  },
  detailItem: {
    display: 'flex',
    marginBottom: '8px',
  },
  detailKey: {
    flex: '1',
    fontWeight: 'bold',
    marginRight: '8px',
    color: '#333',
  },
  detailValue: {
    flex: '2',
    color: '#555',
  },
};

export default Game;



