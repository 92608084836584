import React, { useState } from 'react';

const Deposit = ({ userToken, onInitializeDeposit }) => {
  const [amount, setAmount] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [progress, setProgress] = useState(0); // Added progress state
  const [showProgressBar, setShowProgressBar] = useState(false);

  const handleInitializeDeposit = async () => {
    try {
      if (parseFloat(amount) < 20) {
        setErrorMessage('Deposit amount should be 20 or more. Please enter a valid amount.');
        return;
      }

      setErrorMessage('');
      setShowProgressBar(true); // Show progress bar
      setProgress(10); // Set an initial progress value

      const response = await fetch('https://testboxgame.vercel.app/initialize-deposit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': userToken,
        },
        body: JSON.stringify({ amount }),
      });

      setProgress(50); // Update progress after the fetch

      const data = await response.json();

      if (data.success) {
        setProgress(100); // Set progress to 100 on success
        window.location.href = data.authorizationUrl;
      } else {
        console.error('Initialization failed:', data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const containerStyle = {
    margin: '20px 0',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#fff',
  };

  const headingStyle = {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    marginBottom: '10px',
  };

  const errorStyle = {
    color: 'red',
    marginBottom: '10px',
  };

  const noteStyle = {
    color: '#333',
    marginBottom: '10px',
    fontStyle: 'italic',
  };

  const buttonStyle = {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>Deposit</h2>
      <div style={noteStyle}>
        Note: Always refresh the page to see your new balance update.
      </div>
      {showProgressBar && <progress value={progress} max="100" style={{ width: '100%' }} />}
      <div>
        <label>
          Amount:
          <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} style={inputStyle} />
        </label>
      </div>
      {errorMessage && (
        <div style={errorStyle}>
          <p>{errorMessage}</p>
        </div>
      )}
      <button onClick={handleInitializeDeposit} style={buttonStyle}>
        Initialize Deposit
      </button>
    </div>
  );
};

export default Deposit;
