import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Withdrawal = ({ userToken,userBalance, setUserBalance, onInitializeWithdrawal }) => {
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [notification, setNotification] = useState('');

  const handleInitializeWithdrawal = async () => {
    try {
      const amount = parseFloat(withdrawalAmount);

      if (isNaN(amount) || amount < 50) {
        setErrorMessage('Withdrawal amount should be 50 KSh or more. Please enter a valid amount.');
        setNotification('');
        return;
      }

       // Check if the user has enough balance
      if (userBalance < amount) {
          setErrorMessage('Insufficient balance. Please enter a valid amount.');
          setNotification('');
            return;
        }

      setErrorMessage('');
      setNotification('Withdrawal process has started. You will be notified once it is completed.');



      const response = await fetch('https://testboxgame.vercel.app/transfer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': userToken,
        },
        body: JSON.stringify({ withdrawalAmount }),
      });

      // Check if the withdrawal was successful and handle accordingly

      console.log(response)
     
      if (!response.ok) {
        // Update the user balance after a successful withdrawal
        // setUserBalance(prevBalance => prevBalance - amount);

        setWithdrawalAmount(''); // Reset the withdrawal amount field
        setNotification('Refresh the page to see your new balance.');
        toast.success('Withdrawal ongoing. Refresh the page to see your new balance.');
      } else {
        toast.error('Withdrawal process ongoing.');
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const containerStyle = {
    margin: '20px 0',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#fff',
  };

  const headingStyle = {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    marginBottom: '10px',
  };

  const errorStyle = {
    color: 'red',
    marginBottom: '10px',
  };

  const notificationStyle = {
    color: 'green',
    marginBottom: '10px',
  };

  const buttonStyle = {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>Withdrawal</h2>
      <div>
        <label>
          Withdrawal Amount:
          <input type="number" value={withdrawalAmount} onChange={(e) => setWithdrawalAmount(e.target.value)} style={inputStyle} />
        </label>
      </div>
      {errorMessage && (
        <div style={errorStyle}>
          <p>{errorMessage}</p>
        </div>
      )}
      {notification && (
        <div style={notificationStyle}>
          <p>{notification}</p>
        </div>
      )}
      <button onClick={handleInitializeWithdrawal} style={buttonStyle}>
        Initiate Withdrawal
      </button>
      <ToastContainer position="bottom-right" autoClose={5000} />
    </div>
  );
};

export default Withdrawal;

