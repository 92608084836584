import React, { useState } from 'react';

const Login = ({ onLogin }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [userBalance, setUserBalance] = useState(0);
  const [error, setError] = useState('');

  const handleLogin = async () => {
    try {
      // Validate phone number and password
      if (!phoneNumber || !password) {
        setError('Please enter both phone number and password.');
        return;
      }

      const response = await fetch('https://testboxgame.vercel.app/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const newUserBalance = data.balance;

        // Store the token and user balance in localStorage
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('userBalance', newUserBalance);
        localStorage.setItem('userEmail', data.email);

        // Update the state with the user balance
        setUserBalance(newUserBalance);

        // Call the onLogin callback with the token, user balance, and user email
        onLogin(data.token, newUserBalance, data.email);
      } else {
        // Display error message
        setError('Invalid phone number or password. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
      console.error('Error:', error);
    }
  };

  const containerStyle = {
    maxWidth: '300px',
    margin: '20px auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  };

  const headingStyle = {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    marginBottom: '15px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '4px',
  };

  const buttonStyle = {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  };

  const errorStyle = {
    color: 'red',
    marginBottom: '10px',
  };

  return (
    <div style={containerStyle}>
      <h2 style={headingStyle}>Login</h2>
      {error && <div style={errorStyle}>{error}</div>}
      <div>
        <label>
          Phone Number:
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={inputStyle}
          />
        </label>
      </div>
      <div>
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={inputStyle}
          />
        </label>
      </div>
      <button onClick={handleLogin} style={buttonStyle}>
        Login
      </button>
    </div>
  );
};

export default Login;




